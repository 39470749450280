import axios from "axios";
import jsonBig from "json-bigint";
import ApiPromise from "@/api/api-promise";
import config from "@/config.json";
import ApiError from "@/api/api-error";

const versionPrefix = "v2";
const baseUrl = config.apiBaseURL + "/" + versionPrefix + "/plugins";

class V2Client {
    constructor() {
        this.baseUrl = baseUrl;

        this.httpClient = axios.create({
            baseURL: baseUrl,
            withCredentials: true,
            transformResponse: [
                (data) => {
                    if (!data) {
                        return {};
                    }

                    return jsonBig.parse(data);
                },
            ],
            transformRequest: [
                (data, headers) => {
                    headers["Content-Type"] = "application/json";
                    return jsonBig.stringify(data);
                },
            ],
        });

        this.httpClient.interceptors.response.use(
            (response) => {
                if (response.status === 200) {
                    // Normal response
                    return Promise.resolve(response.data);
                } else {
                    return Promise.reject(new ApiError(ApiError.Type.Unknown));
                }
            },
            (error) => {
                if (error.response && error.response.status) {
                    return this.processErrorStatus(
                        error.response.status,
                        error.response.data
                    );
                } else {
                    console.log(error);
                    return Promise.reject(new ApiError(ApiError.Type.Unknown));
                }
            }
        );

        this.apiForbiddenErrorHandler = null;
        this.apiServerErrorHandler = null;
    }

    post(url, data, config) {
        return new ApiPromise(this.httpClient.post(url, data, config));
    }

    get(url, config) {
        return new ApiPromise(this.httpClient.get(url, config));
    }

    async getAsReadableStream(url) {
        try {
            const response = await fetch(this.baseUrl + url, {
                mode: "cors",
                cache: "no-cache",
                credentials: "include",
                redirect: "follow",
                referrerPolicy: "no-referrer",
            });

            if (response.ok) {
                return Promise.resolve(response.body);
            } else {
                const message = await response.text();
                return this.processErrorStatus(
                    response.status,
                    jsonBig.parse(message)
                );
            }
        } catch (e) {
            return Promise.reject(e);
        }
    }

    processErrorStatus(status, errorData) {
        if (status === 400) {
            return Promise.reject(
                new ApiError(ApiError.Type.Validation, errorData)
            );
        } else if (status === 403) {
            if (typeof this.apiForbiddenErrorHandler === "function") {
                let handler = this.apiForbiddenErrorHandler;
                handler();
            }

            return Promise.reject(new ApiError(ApiError.Type.Forbidden));
        } else if (status === 404) {
            return Promise.reject(new ApiError(ApiError.Type.NotFound));
        } else if (status === 500) {
            if (typeof this.apiServerErrorHandler === "function") {
                let handler = this.apiServerErrorHandler;
                handler();
            }

            return Promise.reject(new ApiError(ApiError.Type.Server));
        } else {
            return Promise.reject(new ApiError(ApiError.Type.Unknown));
        }
    }

    put(url, data, config) {
        return new ApiPromise(this.httpClient.put(url, data, config));
    }

    del(url, config) {
        return new ApiPromise(this.httpClient.delete(url, config));
    }

    onApiForbiddenError(handler) {
        this.apiForbiddenErrorHandler = handler;
    }

    onApiServerError(handler) {
        this.apiServerErrorHandler = handler;
    }
}

const v2 = new V2Client();

export default v2;
