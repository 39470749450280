import { v4 as uuid } from "uuid";
import JsonModel from "@/models/json-model";

// We can't use WebCrypto API since it doesn't support progressive update.
/* global asmCrypto */

const DIP_FILE_KEY = "DIP_FILE_ID"; // PowerPoint supports only Uppercase letters...
let CurrentFileCache = null;
let localCompressedZipFile = null;

class DipFile extends JsonModel {
    constructor(json) {
        super();

        this.id = 0;
        this.name = "";
        this.description = "";
        this.category = 1;
        this.projectId = 0;
        this.hash = "";

        this.fromJson(json);
    }

    createAndWriteLocalFileId() {
        const self = this;

        return new Promise((resolve) => {
            const app = window.Word || window.Excel || window.PowerPoint;

            app.run(async (context) => {
                self.id = uuid();

                const properties = getAppCustomProperty(context);

                properties.add(DIP_FILE_KEY, self.id);

                await context.sync();
            }).then(() => {
                resolve();
            });
        });
    }

    createLocalCompressedZipFile() {
        const self = this;

        return new Promise((resolve, reject) => {
            self.releaseLocalCompressedZipFile().then(() => {
                window.Office.context.document.getFileAsync(
                    window.Office.FileType.Compressed,
                    {},
                    async function (result) {
                        if (
                            result.status ===
                            window.Office.AsyncResultStatus.Succeeded
                        ) {
                            console.log("Created local compressed zip file!");
                            localCompressedZipFile = result.value;
                            resolve(result.value);
                        } else {
                            reject(result.error);
                        }
                    }
                );
            });
        });
    }

    releaseLocalCompressedZipFile() {
        const self = this;

        return new Promise((resolve) => {
            if (self.isLocalCompressedZipFileValid()) {
                console.log("Release local compressed zip file");

                try {
                    localCompressedZipFile.closeAsync(() => {
                        localCompressedZipFile = null;
                        resolve();
                    });
                } catch (e) {
                    console.error(
                        "error while release the compressed zip file"
                    );
                    console.error(e);
                    localCompressedZipFile = null;
                    resolve();
                }
            } else {
                localCompressedZipFile = null;
                resolve();
            }
        });
    }

    isLocalCompressedZipFileValid() {
        return localCompressedZipFile && localCompressedZipFile.getSliceAsync;
    }

    updateHash(onProgress) {
        const self = this;

        return new Promise((resolve, reject) => {
            // We must calculate hash on a new compressed file to keep it correct
            self.createLocalCompressedZipFile()
                .then(async () => {
                    const startTime = Date.now();
                    let hashDuration = 0;
                    let getFileDuration = 0;

                    console.log("Hash started!");

                    const hasher = new asmCrypto.SHA256();

                    try {
                        if (typeof onProgress === "function") {
                            onProgress(0);
                        }

                        for (
                            let i = 0;
                            i < localCompressedZipFile.sliceCount;
                            i++
                        ) {
                            const getSliceStart = Date.now();
                            const sliceData =
                                await self.getCompressedZipFileSlice(i);
                            const getSliceEnd = Date.now();

                            getFileDuration += getSliceEnd - getSliceStart;

                            const digestStart = Date.now();
                            hasher.process(sliceData);
                            const digestEnd = Date.now();

                            hashDuration += digestEnd - digestStart;

                            if (typeof onProgress === "function") {
                                const percentage = Math.floor(
                                    ((i + 1) /
                                        localCompressedZipFile.sliceCount) *
                                        100
                                );
                                onProgress(percentage);
                            }
                        }

                        const digestStart = Date.now();
                        hasher.finish();
                        const digestEnd = Date.now();
                        hashDuration += digestEnd - digestStart;

                        self.hash = asmCrypto.bytes_to_hex(hasher.result);

                        const endTime = Date.now();

                        const totalTime = endTime - startTime;

                        console.log(
                            "Hash total time: " + totalTime / 1000 + " seconds"
                        );
                        console.log(
                            "Get file time: " +
                                getFileDuration / 1000 +
                                " seconds"
                        );
                        console.log(
                            "Hash time: " + hashDuration / 1000 + " seconds"
                        );

                        resolve(self);
                    } catch (err) {
                        reject(err);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    getCompressedZipFileSlice(index) {
        return new Promise((resolve, reject) => {
            localCompressedZipFile.getSliceAsync(index, function (result) {
                if (
                    result.status !== window.Office.AsyncResultStatus.Succeeded
                ) {
                    reject(result.status);
                } else {
                    resolve(new Uint8Array(result.value.data));
                }
            });
        });
    }

    getCompressedZipFileSliceCount() {
        if (!this.isLocalCompressedZipFileValid()) {
            return -1;
        }

        return localCompressedZipFile.sliceCount;
    }

    getCompressedZipFileSize() {
        if (!this.isLocalCompressedZipFileValid()) {
            return -1;
        }

        return localCompressedZipFile.size;
    }

    getCompressedZipFileReadableStream() {
        if (!this.isLocalCompressedZipFileValid()) {
            return null;
        }

        let tmpDocxNextSlice = 0;
        const self = this;

        return new ReadableStream({
            pull(controller) {
                return new Promise((resolve, reject) => {
                    try {
                        if (
                            tmpDocxNextSlice ===
                            self.getCompressedZipFileSliceCount()
                        ) {
                            controller.close();
                            resolve();
                            return;
                        }

                        self.getCompressedZipFileSlice(tmpDocxNextSlice)
                            .then((sliceData) => {
                                controller.enqueue(sliceData);
                                tmpDocxNextSlice += 1;
                                resolve();
                            })
                            .catch((err) => {
                                reject(err);
                            });
                    } catch (err) {
                        console.log("readable stream pull error: ", err);
                        reject(err);
                    }
                });
            },
        });
    }
}

DipFile.categories = [
    {
        value: 1,
        name: "创意作品（视听文学作品）",
    },
    {
        value: 2,
        name: "创意设计（品牌徽标、图案、织物、建筑等）",
    },
    {
        value: 3,
        name: "工业品外观设计（技术原理图、详图、工艺流程等）",
    },
    {
        value: 4,
        name: "代码（用于软件、应用程序、游戏等）",
    },
    {
        value: 5,
        name: "研究（实验室记录、报告等发现）",
    },
    {
        value: 6,
        name: "数据（AI建模、基因测序等）",
    },
    {
        value: 7,
        name: "有数字签名的文件（合同、信件、证书等）",
    },
    {
        value: 8,
        name: "其他（损害资料或未签名文件等）",
    },
];

DipFile.currentFile = async () => {
    const app = window.Word || window.Excel || window.PowerPoint;

    await app.run(async (context) => {
        const properties = getAppCustomProperty(context);
        await buildCurrentFileCache(properties, context);
    });

    CurrentFileCache.name = await getFileName();

    return CurrentFileCache;
};

const getFileName = () => {
    return new Promise((resolve) => {
        window.Office.context.document.getFilePropertiesAsync((asyncResult) => {
            const matches = asyncResult.value.url.match(
                /[\\/]([^\\/.]+(\..+)?)$/
            );

            if (matches.length >= 2) {
                resolve(matches[1]);
            } else {
                resolve("");
            }
        });
    });
};

const getAppCustomProperty = (context) => {
    if (context.workbook) {
        return context.workbook.properties.custom;
    } else if (context.presentation) {
        return context.presentation.tags;
    } else {
        return context.document.properties.customProperties;
    }
};

const buildCurrentFileCache = async (properties, context) => {
    properties.load("key,type,value");
    await context.sync();

    const id = properties.getItemOrNullObject(DIP_FILE_KEY);
    id.load("value, isNullObject");
    await context.sync();

    if (
        id.isNullObject ||
        !(CurrentFileCache && CurrentFileCache.id === id.value)
    ) {
        console.log("DIP ID not found or cache is invalid");
        if (CurrentFileCache) {
            await CurrentFileCache.releaseLocalCompressedZipFile();
        }

        CurrentFileCache = new DipFile({ id: id.value });

        if (id.isNullObject) {
            console.log("DIP ID not found. Create a new one");
            await CurrentFileCache.createAndWriteLocalFileId();
        }
    } else {
        console.log("DIP ID found and cache is valid: " + id.value);
    }
};

export default DipFile;
