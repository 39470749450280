class JsonModel {
    fromJson(json) {
        const self = this;

        if (typeof json !== "undefined") {
            Object.keys(self).forEach((key) => {
                if (key in json) {
                    self[key] = json[key];
                }
            });
        }
    }
}

export default JsonModel;
