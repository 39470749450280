class ApiError {
    constructor(type, data) {
        this.type = type;
        this.data = data;
    }
}

ApiError.Type = {
    Validation: 1,
    Server: 2,
    Forbidden: 3,
    NotFound: 4,
    Unknown: 5,
};

export default ApiError;
